// TODO: move to vuex store
import {
  computed,
  ref,
  readonly,
  watch,
} from 'vue';
import store from '@/store';
import getConstant from '@/constant';
import { get, getValidRange } from '@xsys2/functions';

export default function navigationController() {
  // category
  const ACTIVE_NAVIGATION_GROUPS = getConstant(
    'TECHNICAL_ROOM.ACTIVE_NAVIGATION_GROUPS',
  );
  const productCategoriesGroups = computed(
    () => store.state.technicalRoom.productCategoriesGroups,
  );
  const activeCategoryIndexes = ref({
    groupIndex: 0,
    categoryIndex: 0,
  });

  const activeCategory = computed(() => getCategoryFromIndexes(
    activeCategoryIndexes.value.groupIndex,
    activeCategoryIndexes.value.categoryIndex,
  ));

  const canCategoryBeSetted = ([groupIndex]) => ACTIVE_NAVIGATION_GROUPS
    .includes(groupIndex);

  const setActiveCategory = (groupIndex, categoryIndex) => {
    activeCategoryIndexes.value.groupIndex = groupIndex;
    activeCategoryIndexes.value.categoryIndex = categoryIndex;
  };

  const getCategoryFromIndexes = (groupIndex, categoryIndex) => get(
    productCategoriesGroups.value,
    `${groupIndex}.productCategoriesList[${categoryIndex}]`,
  );

  // product
  const activeProductIndex = ref(0);
  const setActiveProductIndex = index => (activeProductIndex.value = index);
  const activeProduct = computed(() => {
    if (activeCategory.value) {
      const { products } = activeCategory.value;

      return products[
        getValidRange(activeProductIndex.value, products.length - 1, 0)
      ];
    }
  });

  watch(activeCategory, () => setActiveProductIndex(0));

  return {
    activeCategory,
    canCategoryBeSetted,
    setActiveCategory,
    getCategoryFromIndexes,

    activeProductIndex: readonly(activeProductIndex),
    setActiveProductIndex,
    activeProduct,
  };
}
