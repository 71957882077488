import { get } from '@xsys2/functions';

export default path => get(
  {
    TECHNICAL_ROOM: {
      ACTIVE_NAVIGATION_GROUPS: [0],
    },
  },
  path,
);
