import { ref, watch, readonly } from 'vue';
import store from '@/store';
import { isFirefox } from '@xsys2/functions';

export default function formController() {
  const isWrapperOpened = ref(false);

  const wrapperProps = ref({});
  const setWrapperProps = props => (wrapperProps.value = props ?? {});

  const toggleWrapper = value => (isWrapperOpened.value = value);
  const serviceWrapperProps = {
    bgColor: isFirefox() ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.30)',
    blur: '32',
    style: {
      zIndex: 30,
    },
  };

  watch(isWrapperOpened, (value) => {
    store.commit(
      'ui/SET_SERVICE_WRAPPER_PROPS',
      value
        ? serviceWrapperProps
        : null,
    );
  });

  return {
    isWrapperOpened,
    toggleWrapper,

    wrapperProps: readonly(wrapperProps),
    setWrapperProps,
  };
}
