/* eslint-disable */
// TODO: split logic with vuex
// TODO: refactoring
import {
  ref,
  computed,
  shallowRef,
  watch,
  reactive,
} from 'vue';
import { get, set } from '@xsys2/functions';
import store from '@/store';

// components
import {
  AppForm,
  TechnicalRoomProductDocuments,
  TechnicalRoomBenefits,
} from '@xsys2/components';

// modules
import {
  navigationController,
  wrapperController,
} from '@/modules/technicalRoom';

const {
  activeCategory,
  canCategoryBeSetted,
  setActiveCategory,

  activeProductIndex,
  setActiveProductIndex,
  activeProduct,
} = navigationController();

// computeds
const pageTitle = computed(
  () => store.state.technicalRoom.pageTitle,
);
const pageDescription = computed(
  () => store.state.technicalRoom.pageDescription,
);
const ctaBlock = computed(
  () => store.state.technicalRoom.ctaBlock,
);
const productCategoriesGroups = computed(
  () => store.state.technicalRoom.productCategoriesGroups,
);
const contactFormProduct = computed(
  () => store.state.technicalRoom.contactFormProduct,
);
const contactFormProductRequestAnApointment = computed(
  () => store.state.technicalRoom.contactFormProductRequestAnApointment,
);
const benefitsTitle = computed(
  () => activeCategory.value.yourBenefits.yourBenefitsTitle,
);
const benefitsContent = computed(
  () => activeCategory.value.yourBenefits.yourBenefitsContent,
);
const productSinglPage = computed(
  () => activeProduct.value?.productSinglPage,
);

// wrapper
const {
  isWrapperOpened,
  toggleWrapper,
  wrapperProps,
  setWrapperProps,
} = wrapperController();

const wrapperComponent = shallowRef(AppForm);
const wrapperComponents = {
  AppForm: shallowRef(AppForm),
  TechnicalRoomProductDocuments: shallowRef(TechnicalRoomProductDocuments),
};

const switchWrapperComponent = (name) => {
  if (wrapperComponent.value !== wrapperComponents[name].value) {
    wrapperComponent.value = wrapperComponents[name].value;
  }
};

// product documents
const documents = computed(() => activeProduct.value?.documents);
const languages = computed(
  () => Array.from(
    documents.value?.reduce(
      (acc, document) => {
        document.languages.forEach(language => acc.add(language.label));

        return acc;
      },
      new Set(),
    )
  ),
);
const activelanguage = ref(null);
const documentsByActiveLanguage = computed(() => {
  if (documents.value && activelanguage.value) {
    return documents.value
      .filter(document => document.languages.some(
        language => language.label === activelanguage.value,
      ))
      .map(({ documentTitle: name, documentUrl: link }) => ({ name, link }));
  }
});

watch(activeProduct, () => {
  if (documents.value) {
    activelanguage.value = languages.value[0];
  }
});

// preload machines static images
const machinesImages = reactive({});
const productImages = computed(() => get(
  machinesImages,
  `[${activeCategory.value?.categoryName}][${activeProduct.value?.productName}]`,
));

const loadImages = async (categoryName, productName) => {
  const promises = [];

  for (let i = 0; i < 36; i++) {
    console.log(`@xsys2/assets/machines/${categoryName}/${productName}/${i}.png`);

    promises.push(
      import(`@xsys2/assets/machines/${categoryName}/${productName}/${i}.png`),
    );
  }

  const modules = await Promise.allSettled(promises);

  const images = modules
    .map(module => (
      module.status === 'rejected'
        ? null
        : module.value.default
    ))
    .filter(Boolean);

  return images.length
    ? images
    : null;
};

const resortImages = (images = []) => {
  if (images?.length === 36) {
    const frontImage = images.pop();
    const imagesCenterIndex = Math.floor(images.length / 2);

    const leftHalf = images
      .slice(0, imagesCenterIndex);

    const rightHalf = images
      .slice(imagesCenterIndex)
      .concat([frontImage]);

    const [backImage] = rightHalf;

    return rightHalf.concat(leftHalf, [backImage]);
  }

  return images;
};

watch(
  [activeCategory, activeProduct],
  ([{ categoryName }, { productName }]) => {
    const objectPath = `[${categoryName}][${productName}]`;

    if (!get(machinesImages, objectPath)) {
      loadImages(categoryName, productName)
        .then(images => set(
          machinesImages,
          objectPath,
          resortImages(images),
        ));
    }
  },
);

// ORDER:
// BackgroundImage
// AppLogo
// TechnicalRoomNavigationWrapper
// AppTooltip
// ViewMoreButton
// TechnicalRoomApointment
// AppWrapper
// TechnicalRoomProductsSwiper
// ButtonMoreInfo
// ButtonClose
// TechnicalRoomProductLabel
// TechnicalRoomCollapsablePanel
// TechnicalRoomProductSwitcher
// TechnicalRoomProductView
// RequestDemoButton
// DownloadFlyersButton

// STATUSES:
// positioning
// active
// hiding

export default [
  {
    BackgroundImage: {
      props: {
        key: 'categories background image',
        width: '1920',
        height: '1080',
        src: 'backgrounds/Technical Room.png',
        alt: 'categories background image',
        draggable: false,
      },
      style: {
        transition: 'all 1s ease-in-out',
        transform: 'scale(1) translateY(0)',
      },
    }, // active
    AppLogo: {
      isMounted: true,
      style: {
        top: '7.031vw',
        width: '12.500vw',
        transition: 'all 1s ease-in-out',
      },
    }, // active
    TechnicalRoomNavigationWrapper: {
      props: {
        key: pageTitle,
        backButtonLabel: 'Back',
        title: pageTitle,
        description: pageDescription,
      },
      slot: {
        isMounted: true,
        is: 'TechnicalRoomNavigationCategories',
        props: {
          activeCategory,
          categories: productCategoriesGroups,
        },
        listeners: {
          onClick([groupIndex, categoryIndex]) {
            if (canCategoryBeSetted([groupIndex, categoryIndex])) {
              setActiveCategory(groupIndex, categoryIndex);
            } else {
              switchWrapperComponent('AppForm');

              const {
                productCategoriesList,
                contactFormCategory: {
                  fields,
                  formText,
                  formTitle,
                },
              } = productCategoriesGroups.value[groupIndex];
              const { categoryName } = productCategoriesList[categoryIndex];

              setWrapperProps({
                title: formTitle,
                description: formText,
                inputs: fields,
                subject: `Technical Room - ${categoryName}`,
              });

              toggleWrapper(true);
            }
          },
        },
      },
    }, // active
    AppTooltip: {
      isMounted: activeCategory,
      props: {
        type: 'gray',
        message: computed(() => activeCategory.value.categoryName),
        additionalCondition: Boolean(
          computed(() => activeCategory.value.categoryName),
        ),
      },
      style: {
        bottom: '7.4vw',
        maxHeight: '22vw',
        transition: 'all 1s ease-in-out',
      },
      slot: {
        props: {
          key: computed(() => activeProduct.value.productName),
          webp: computed(() => activeProduct.value.productImageWebp.url),
          png: computed(() => activeProduct.value.productImage.url),
          alt: computed(() => activeProduct.value.productName),
        },
      },
    }, // active
    ViewMoreButton: {
      isMounted: true,
    }, // active
    TechnicalRoomApointment: {
      isMounted: ctaBlock,
      props: {
        data: ctaBlock,
      },
      listeners: {
        onSubmit() {
          switchWrapperComponent('AppForm');

          const {
            formTitle,
            formText,
            fields,
          } = contactFormProductRequestAnApointment.value;

          setWrapperProps({
            title: formTitle,
            description: formText,
            inputs: fields,
            subject: 'Technical Room - Request an Apointment',
          });

          toggleWrapper(true);
        },
      },
    }, // active
    AppWrapper: {
      isMounted: isWrapperOpened,
      slot: {
        is: wrapperComponent,
        props: wrapperProps,
        listeners: {
          async onSubmit({ resetForm, subject, values }) {
            const result = await store.dispatch(
              'sendForm',
              { subject, message: values },
            );

            if (result) {
              resetForm();
              toggleWrapper(false);
            }
          },
        },
      },
      onClickOutside() {
        setWrapperProps(null);
        toggleWrapper(false);
      },
    }, // active
    TechnicalRoomProductsSwiper: {
      isMounted: false,
    }, // positioning
    ButtonMoreInfo: {
      isMounted: false,
    }, // positioning
    ButtonClose: {
      isMounted: false,
    }, // positioning
    TechnicalRoomProductLabel: {
      isMounted: false,
    }, // positioning
    TechnicalRoomCollapsablePanel: {
      isMounted: false,
    }, // positioning
    TechnicalRoomProductSwitcher: {
      isMounted: false,
    }, // positioning
    TechnicalRoomProductView: {
      isMounted: false,
    }, // positioning
    RequestDemoButton: {
      isMounted: false,
    }, // positioning
    DownloadFlyersButton: {
      isMounted: false,
    }, // positioning
  },
  {
    BackgroundImage: {
      props: {
        key: 'categories background image',
        width: '1920',
        height: '1080',
        src: 'backgrounds/Technical Room.png',
        alt: 'categories background image',
        draggable: false,
      },
      style: {
        transition: 'all 1s ease-in-out',
        transform: 'scale(1.4) translateY(-6vw)',
      },
    }, // active
    AppLogo: {
      isMounted: true,
      style: {
        top: '2.083vw',
        width: '8.333vw',
        transition: 'all 1s ease-in-out',
      },
    }, // active
    TechnicalRoomNavigationWrapper: {
      props: {
        backButtonLabel: 'Equipment demo',
        title: computed(() => activeCategory.value.categoryName),
        description: computed(() => activeCategory.value.categoryDescription),
      },
      style: {
        transform: 'translateX(0)',
        transition: 'all 1s ease-in-out',
      },
      slot: {
        isMounted: true,
        is: shallowRef(TechnicalRoomBenefits),
        props: {
          title: benefitsTitle,
          content: benefitsContent,
        },
        style: {
          margin: '-1.667vw 0 0 -1vw',
        },
      },
    }, // active
    AppTooltip: {
      isMounted: true,
      props: {
        type: 'gray',
        message: null,
        additionalCondition: false,
      },
      style: {
        bottom: '7.4vw',
        maxHeight: '25vw',
        transition: 'all 1s ease-in-out',
      },
      slot: {
        props: {
          key: computed(() => activeProduct.value.productName),
          webp: computed(() => activeProduct.value.productImageWebp.url),
          png: computed(() => activeProduct.value.productImage.url),
          alt: computed(() => activeProduct.value.productName),
        },
      },
    }, // hiding
    ViewMoreButton: {
      isMounted: false,
    }, // hiding
    TechnicalRoomApointment: {
      isMounted: false,
    }, // hiding
    AppWrapper: {
      isMounted: false,
    }, // hiding
    TechnicalRoomProductsSwiper: {
      isMounted: activeCategory,
      props: {
        activeIndex: activeProductIndex,
        items: computed(
          () => activeCategory.value.products
            .map(product => product.productName),
        ),
      },
      listeners: {
        updateActiveIndex: setActiveProductIndex,
      },
    }, // active
    ButtonMoreInfo: {
      isMounted: true,
    }, // active
    ButtonClose: {
      isMounted: false,
    }, // hiding
    TechnicalRoomProductLabel: {
      isMounted: false,
    }, // hiding
    TechnicalRoomCollapsablePanel: {
      isMounted: false,
    }, // hiding
    TechnicalRoomProductSwitcher: {
      isMounted: false,
    }, // hiding
    TechnicalRoomProductView: {
      isMounted: false,
    }, // hiding
    RequestDemoButton: {
      isMounted: false,
    }, // hiding
    DownloadFlyersButton: {
      isMounted: false,
    }, // hiding
  },
  {
    BackgroundImage: {
      props: {
        key: 'product background image',
        width: '1920',
        height: '1080',
        src: 'backgrounds/Technical Room 2.png',
        alt: 'product background image',
        draggable: false,
      },
      style: {
        transition: 'all 1s ease-in-out',
        transform: 'scale(1) translateY(0)',
      },
    }, // active
    AppLogo: {
      isMounted: true,
      style: {
        top: '2.083vw',
        width: '8.333vw',
        opacity: 0,
        transition: 'all 1s ease-in-out',
      },
    }, // hiding
    TechnicalRoomNavigationWrapper: {
      props: {
        backButtonLabel: 'Equipment demo',
        title: computed(() => activeCategory.value.categoryName),
        description: computed(() => activeCategory.value.categoryDescription),
      },
      style: {
        transform: 'translateX(-100%)',
        transition: 'all 1s ease-in-out',
      },
      slot: {
        isMounted: true,
        is: shallowRef(AppForm),
        props: {
          title: computed(() => contactFormProduct.value.formTitle),
          description: computed(() => contactFormProduct.value.formText),
          inputs: computed(() => contactFormProduct.value.fields),
        },
        listeners: {},
      },
    }, // hiding
    AppTooltip: {
      isMounted: computed(() => !Boolean(productImages.value)),
      props: {
        type: 'gray',
        message: null,
        additionalCondition: false,
      },
      style: {
        bottom: '20%',
        maxHeight: '50vh',
        cursor: 'default',
        transition: 'all 1s ease-in-out',
      },
      slot: {
        props: {
          key: computed(() => activeProduct.value.productName),
          webp: computed(() => activeProduct.value.productImageWebp.url),
          png: computed(() => activeProduct.value.productImage.url),
          alt: computed(() => activeProduct.value.productName),
        },
      },
    }, // active
    ViewMoreButton: {
      isMounted: false,
    }, // hiding
    TechnicalRoomApointment: {
      isMounted: false,
    }, // hiding
    AppWrapper: {
      isMounted: isWrapperOpened,
      slot: {
        is: wrapperComponent,
        props: wrapperProps,
        listeners: {
          async onSubmit({ resetForm, subject, values }) {
            const result = await store.dispatch(
              'sendForm',
              { subject, message: values },
            );

            if (result) {
              resetForm();
              toggleWrapper(false);
            }
          },
          changeLanguage(language) {
            activelanguage.value = language;
          },
        },
      },
      onClickOutside() {
        setWrapperProps(null);
        toggleWrapper(false);
      },
    }, // active
    TechnicalRoomProductsSwiper: {
      isMounted: false,
    }, // hiding
    ButtonMoreInfo: {
      isMounted: false,
    }, // hiding
    ButtonClose: {
      isMounted: true,
    }, // active
    TechnicalRoomProductLabel: {
      isMounted: true,
      props: {
        key: activeProductIndex,
        label: computed(() => activeProduct.value.productName),
      },
    }, // active
    TechnicalRoomCollapsablePanel: {
      isMounted: true,
      key: computed(() => productSinglPage.value.pageTitle),
      props: {
        title: computed(() => productSinglPage.value.pageTitle),
        subtitle: computed(() => productSinglPage.value.pageSubtitle),
        subtitle2: computed(() => productSinglPage.value.pageSubtitle2),
        panels: computed(() => productSinglPage.value.infoblocks),
      },
    }, // active
    TechnicalRoomProductSwitcher: {
      isMounted: true,
      props: {
        activeIndex: activeProductIndex,
        items: computed(() => activeCategory.value.products),
      },
      listeners: {
        updateActiveIndex: setActiveProductIndex,
      },
    }, // active
    TechnicalRoomProductView: {
      isMounted: computed(() => Boolean(productImages.value)),
      props: {
        key: productImages,
        images: productImages,
      },
    }, // active
    RequestDemoButton: {
      isMounted: true,
      listeners: {
        click() {
          switchWrapperComponent('AppForm');

          const {
            formTitle,
            formText,
            fields,
          } = contactFormProduct.value;

          setWrapperProps({
            title: formTitle,
            description: formText,
            inputs: fields,
            subject: 'Technical Room'
              .concat(' - ')
              .concat(activeCategory.value.categoryName)
              .concat(' - ')
              .concat(activeProduct.value.productName),
          });

          toggleWrapper(true);
        },
      },
    }, // active
    DownloadFlyersButton: {
      isMounted: documents,
      listeners: {
        click() {
          switchWrapperComponent('TechnicalRoomProductDocuments');

          setWrapperProps({
            title: 'Download Flyers',
            productName: activeProduct.value.productName,
            activelanguage,
            languages,
            documents: documentsByActiveLanguage,
          });

          toggleWrapper(true);
        },
      },
    }, // active
  },
];
