import { reactive } from 'vue';
import router from '@/router';
import { forEach } from '@xsys2/functions';
import componentsProps from './componentsProps';

export default function componentsController() {
  const props = reactive({});
  let currentPropsIndex = 0;

  function applyProps(newProps) {
    forEach(newProps, (key, value) => {
      props[key] = value;
    });
  }

  function next() {
    if (currentPropsIndex < (componentsProps.length - 1)) {
      applyProps(componentsProps[++currentPropsIndex]);
    }
  }

  function prev() {
    if (currentPropsIndex > 0) {
      applyProps(componentsProps[--currentPropsIndex]);
    } else {
      router.go(-1);
    }
  }

  applyProps(componentsProps[currentPropsIndex]);

  return {
    currentPropsIndex,
    props,
    next,
    prev,
  };
}
