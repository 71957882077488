<template>
  <div data-vue-component-name="TechnicalRoom">
    <!-- background -->
    <transition name="fade">
      <img
        class="background"
        v-bind="props.BackgroundImage.props"
        :style="props.BackgroundImage.style"
      >
    </transition>

    <!-- logo -->
    <transition name="fade" appear>
      <AppLogo
        v-if="props.AppLogo.isMounted"
        v-bind="props.AppLogo.props"
        :style="props.AppLogo.style"
      />
    </transition>

    <!-- navigation -->
    <transition name="fade">
      <TechnicalRoomNavigationWrapper
        v-bind="props.TechnicalRoomNavigationWrapper.props"
        :style="props.TechnicalRoomNavigationWrapper.style"
        @onBack="prev"
      >
        <component
          v-if="props.TechnicalRoomNavigationWrapper.slot.isMounted"
          :is="props.TechnicalRoomNavigationWrapper.slot.is"
          v-bind="props.TechnicalRoomNavigationWrapper.slot.props"
          v-on="props.TechnicalRoomNavigationWrapper.slot.listeners"
          :style="props.TechnicalRoomNavigationWrapper.slot.style"
        />
      </TechnicalRoomNavigationWrapper>
    </transition>

    <!-- machine -->
    <AppTooltip
      v-if="props.AppTooltip.isMounted"
      v-bind="props.AppTooltip.props"
      :style="props.AppTooltip.style"
      @click="next"
    >
      <transition name="fade-offsetY" mode="out-in" appear>
        <AppImage v-bind="props.AppTooltip.slot.props" />
      </transition>
    </AppTooltip>

    <!-- view more button -->
    <transition name="fade" appear>
      <AppButton
        v-if="props.ViewMoreButton.isMounted"
        :style="props.ViewMoreButton.style"
        class="view-more-btn"
        pill
        radius="2vw"
        type="fill"
        background="primary"
        @click="next"
      >
        <span>View more</span>
        <IconArrow />
      </AppButton>
    </transition>

    <!-- Apointment With a Technical -->
    <transition name="fade">
      <TechnicalRoomApointment
        v-if="props.TechnicalRoomApointment.isMounted"
        v-bind="props.TechnicalRoomApointment.props"
        v-on="props.TechnicalRoomApointment.listeners"
      />
    </transition>

    <!-- wrapper -->
    <transition name="fade">
      <AppWrapper
        v-if="props.AppWrapper.isMounted"
        class="wrapper"
        bg-color="rgba(255, 255, 255, 0.2)"
        v-click-outside="props.AppWrapper.onClickOutside"
      >
        <component
          :is="props.AppWrapper.slot.is"
          v-bind="props.AppWrapper.slot.props"
          v-on="props.AppWrapper.slot.listeners"
          :style="props.AppWrapper.slot.style"
        />
      </AppWrapper>
    </transition>

    <!-- products swiper -->
    <transition name="fade-swiper">
      <TechnicalRoomProductsSwiper
        v-if="props.TechnicalRoomProductsSwiper.isMounted"
        v-bind="props.TechnicalRoomProductsSwiper.props"
        v-on="props.TechnicalRoomProductsSwiper.listeners"
      />
    </transition>

    <!-- more info button -->
    <transition name="fade">
      <ButtonMoreInfo
        v-if="props.ButtonMoreInfo.isMounted"
        :style="props.ButtonMoreInfo.style"
        @click="next"
      />
    </transition>

    <!-- button close -->
    <transition name="fade">
      <ButtonClose
        v-if="props.ButtonClose.isMounted"
        @click="prev"
      />
    </transition>

    <!-- product label -->
    <transition name="fade">
      <TechnicalRoomProductLabel
        v-if="props.TechnicalRoomProductLabel.isMounted"
        v-bind="props.TechnicalRoomProductLabel.props"
      />
    </transition>

    <!-- collabsable panel -->
    <transition name="fade">
      <div
        class="collabsable-panel"
        v-if="props.TechnicalRoomCollapsablePanel.isMounted"
        :key="props.TechnicalRoomCollapsablePanel.key"
      >
        <h1 class="title">
          {{ props.TechnicalRoomCollapsablePanel.props.title }}
        </h1>

        <h2 class="subtitle">
          {{ props.TechnicalRoomCollapsablePanel.props.subtitle }}
        </h2>

        <p class="subtitle-2">
          {{ props.TechnicalRoomCollapsablePanel.props.subtitle2 }}
        </p>

        <TechnicalRoomCollapsablePanel
          v-if="props.TechnicalRoomCollapsablePanel.props.panels"
          :panels="props.TechnicalRoomCollapsablePanel.props.panels"
        />

        <AppButton
          v-if="props.DownloadFlyersButton.isMounted"
          v-on="props.DownloadFlyersButton.listeners"
          pill
          type="fill"
          background="primary"
          radius="70px"
          class="download-flyers-button"
        >
          <span>Download Flyers</span>
          <IconDownloadCloud />
        </AppButton>
      </div>
    </transition>

    <!-- product switcher -->
    <transition name="fade">
      <TechnicalRoomProductSwitcher
        v-if="props.TechnicalRoomProductSwitcher.isMounted"
        v-bind="props.TechnicalRoomProductSwitcher.props"
        v-on="props.TechnicalRoomProductSwitcher.listeners"
      />
    </transition>

    <!-- TechnicalRoomProductView -->
    <transition name="fade">
      <TechnicalRoomProductView
        v-if="props.TechnicalRoomProductView.isMounted"
        v-bind="props.TechnicalRoomProductView.props"
      />
    </transition>

    <!-- request demo button -->
    <transition name="fade">
      <AppButton
        v-if="props.RequestDemoButton.isMounted"
        v-on="props.RequestDemoButton.listeners"
        pill
        type="fill"
        background="primary"
        radius="70px"
        class="request-demo-btn"
      >
        <span>Request Demo</span>
        <IconArrow />
      </AppButton>
    </transition>
  </div>
</template>

<script setup>
// dependencies
import { useStore } from 'vuex';

// components
import {
  AppWrapper,
  AppLogo,
  AppButton,
  AppImage,
  IconArrow,
  TechnicalRoomProductsSwiper,
  TechnicalRoomCollapsablePanel,
  TechnicalRoomProductView,
  IconDownloadCloud,
} from '@xsys2/components';

// modules
import { metaController } from '@/modules';
import { componentsController } from '@/modules/technicalRoom';
import ButtonClose from '@xsys2/components/src/components/ButtonClose.vue';

// meta
metaController({
  title: 'Technical Room Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/Technical Room.png',
      as: 'image',
    },
    {
      rel: 'preload',
      href: 'backgrounds/Technical Room 2.png',
      as: 'image',
    },
  ],
});

const store = useStore();
store.dispatch('technicalRoom/getData');

const {
  props,
  next,
  prev,
} = componentsController();
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-offsetY-enter-active,
.fade-offsetY-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-offsetY-enter-from,
.fade-offsetY-leave-to {
  margin-bottom: -1vw;
  opacity: 0;
}

.fade-swiper-enter-active,
.fade-swiper-leave-active {
  transform: translate(-50%, 0) !important;
  transition: all 0.5s ease-in-out;
}
.fade-swiper-enter-from,
.fade-swiper-leave-to {
  transform: translate(-50%, 100%) !important;
  opacity: 0;
}

[data-vue-component-name="TechnicalRoom"] {
  position: relative;
  height: 100vh;
  width: 100vw;

  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: inherit;
    height: inherit;
    object-fit: cover;

    &[alt*="categories"] {
      object-fit: contain;
      object-position: bottom;
    }
  }

  [data-vue-component-name="AppLogo"] {
    position: absolute;
    top: 7.031vw;
    left: 50%;
    transform: translateX(-50%);
  }

  [data-vue-component-name="TechnicalRoomNavigationWrapper"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20.833vw;
    padding: 1.7vw;

    @include firefox {
      background: rgba(255, 255, 255, 0.16) !important;
    }
  }

  [data-vue-component-name="TechnicalRoomApointment"] {
    position: absolute;
    right: 1.667vw;
    bottom: 1.667vw;

    @include firefox {
      background: rgba(255, 255, 255, 0.24) !important;
    }
  }

  [data-vue-component-name="AppTooltip"] {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    [data-vue-component-name="AppImage"] {
      cursor: pointer;
      object-fit: contain;
    }
  }

  .view-more-btn {
    position: absolute;
    left: 50%;
    bottom: 5vw;
    transform: translateX(-50%);
    padding: 1.250vw 5.05vw;
    margin-top: auto;

    span {
      font-weight: 600;
      font-size: 0.833vw;
      line-height: 1.146vw;
      text-transform: uppercase;
      color: #FFFFFF;
      white-space: nowrap;
      margin-right: 0.25vw;
    }

    [data-vue-component-name="IconArrow"] {
      width: 0.833vw;
      height: 0.833vw;
    }
  }

  [data-vue-component-name="TechnicalRoomProductsSwiper"] {
    position: absolute;
    left: 50%;
    bottom: 1.2vw;
    transform: translateX(-50%);
    transition: all 1s ease-in-out;

    @include firefox {
      background: rgba(255, 255, 255, 0.16) !important;
    }
  }

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%, -50%);
    padding: 2.500vw;

    @include firefox {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.16),
        rgba(255, 255, 255, 0.16)
      ), #000000 !important;
    }

    [data-vue-component-name="AppForm"] {
      width: 20.833vw;
    }
  }

  [data-vue-component-name="AppForm"] {
    overflow-y: auto;
  }

  [data-vue-component-name="ButtonMoreInfo"] {
    position: absolute;
    left: 50%;
    top: 10.052vw;
    transform: translateX(-50%);
  }

  [data-vue-component-name="ButtonClose"] {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 3.333vw;
    transform: translateX(-50%);
  }

  [data-vue-component-name="TechnicalRoomProductLabel"] {
    @include absolute-full;
    z-index: 0;
  }

  .collabsable-panel {
    position: absolute;
    z-index: 2;
    left: 2.500vw;
    top: 50%;
    transform: translateY(-50%);
    color: #FFFFFF;
    font-style: normal;
    max-width: 20vw;
    width: 100%;

    & > {
      .title {
        font-weight: 800;
        font-size: 1.667vw;
        line-height: 2.292vw;
        letter-spacing: 0.1em;
        margin-bottom: 0.833vw;
      }

      .subtitle {
        font-weight: 800;
        font-size: 0.833vw;
        line-height: 1.146vw;
        margin-bottom: 0.833vw;
      }

      .subtitle-2 {
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 150%;
        opacity: 0.75;
        margin-bottom: 1.250vw;
      }

      [data-vue-component-name="TechnicalRoomCollapsablePanel"] {
        margin-bottom: 1.250vw;
      }

      .download-flyers-button {
        @include flex-center;
        padding: 0.833vw 1.250vw;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 0.833vw;
          line-height: 1.146vw;
          margin-right: 0.417vw;
        }

        [data-vue-component-name="IconDownloadCloud"] {
          display: block;
          width: 1.3vw;
          height: 1.3vw;
        }
      }
    }
  }

  [data-vue-component-name="TechnicalRoomProductSwitcher"] {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 2.500vw;
    transform: translateY(-50%);
  }

  [data-vue-component-name="TechnicalRoomProductView"] {
    @include absolute-full;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::v-deep([data-vue-component-name="TechnicalRoomProductViewImages"]) img {
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(100% - 40%);
      object-fit: contain;
    }

    &::v-deep([data-vue-component-name="TechnicalRoomProductViewSlider"]) {
      bottom: 12%;
    }
  }

  .request-demo-btn {
    position: absolute;
    left: 50%;
    bottom: 2%;
    transform: translate(-50%, 0);
    padding: 1.250vw 4.948vw;
    z-index: 1;

    span {
      text-transform: uppercase;
      margin-right: 0.417vw;
      font-size: 0.833vw;
    }

    [data-vue-component-name="IconArrow"] {
      width: 0.833vw;
      height: 0.833vw;
    }
  }
}
</style>
